<template>
  <div></div>
</template>
<script>
var wx = require('weixin-js-sdk')
export default {
  data () {
    return {
      title: '',
      desc: '',
      link: '',
      img: ''
    }
  },
  mounted () {
  },
  methods: {
    async init (title, desc, link, img) {
      var that = this
      this.title = title
      this.desc = desc
      this.link = link
      this.img = img
      var path = window.location.href
      var pd = { debug: 'false', jsapi: 'updateAppMessageShareData,updateTimelineShareData,getLocation', url: path }
      const { data: res } = await this.$http.post(this.UMS_URL + '/system/config/createWJsapiConfig', this.$qs.stringify(pd))
      if (res.status === '200') {
        wx.config(res.data)
        that.initShare()
      }
    },
    initParam (title, desc, link, img) {
      var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var user = window.sessionStorage.getItem(this.SESSION_USER)
      var site = window.sessionStorage.getItem(this.SESSION_SITE)
      link = link + '&sourceType=RFE&source=RFE_WPP'

      if (operator !== undefined && operator !== null && operator !== '') {
        link = link + '&operator=' + operator
      }
      if (seller !== undefined && seller !== null && seller !== '') {
        link = link + '&seller=' + seller
      }
      if (site !== undefined && site !== null && site !== '') {
        link = link + '&site=' + site
      }
      if (user !== undefined && user !== null && user !== '') {
        link = link + '&referee=' + user
      }
      this.init(title, desc, link, img)
    },
    default (module) {
      if (module === 'seller') {
        this.sellerDefault()
      } else if (module === 'main') {
        this.mainDefault()
      }
    },
    sellerDefault () {
      var title = '微职客-将您的人脉转化为财富'
      var desc = '小孩上学、学历工作、技能证书、留学移民多种任务转发分享就可以轻松赚取高额分成奖励,帮您将人脉变成财富!'
      var link = 'https://moc.utopanet.com/seller/home/train'
      var logo = 'https://moc.utopanet.com/static/logo.png'
      this.initParam(title, desc, link, logo)
    },
    mainDefault () {
      var app = window.sessionStorage.getItem(this.SESSION_APP)
      if (app === 'WPP_MOC') {
        var title = '人生有难题，就来人生帮'
        var desc = 'K12上学、学历提升、证书考培、工作推荐、职称考评、出国签证、出国留学、出国务工，专业团队，线下服务，帮您解决人生难题！'
        var link = 'https://bms.utp.ink/integrate/home?f=1'
        var logo = 'https://bms.utp.ink/static/logo.png'
        this.initParam(title, desc, link, logo)
      }
    },
    initShare () {
      var that = this
      wx.updateAppMessageShareData({
        title: that.title, // 分享标题
        desc: that.desc, // 分享描述
        link: that.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: that.img, // 分享图标
        success: function () {
          // 设置成功
        }
      })
      wx.updateTimelineShareData({
        title: that.title, // 分享标题
        link: that.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: that.img, // 分享图标
        success: function () {
          // 设置成功
        }
      })
    }
  }
}
</script>
